.card-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  margin: 0 auto;
  padding: 75px 0;
  color: #2d9cdb;
  background-size: cover;
}

.join-card-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(310px, auto));
  grid-gap: 60px 30px;
  padding-top: 35px;
}

.join-us-button {
  width: auto;
  padding: 0 10px;
}

.join-us-card.card {
  border-radius: 4px;
  box-shadow: 0px 3px 15px #00000029;
  padding: 40px;
  text-align: center;
}

.join-us-card.card:hover {
  box-shadow: 0px 3px 15px #afafaf;
}

.join-us-card.card > .card-body {
  font-size: 16px;
  padding: 0;
}

.join-us-card.card > .card-img-top {
  margin: 0 auto;
  height: 130px;
  width: auto;
}

.join-us-card.card > div > .card-title {
  font-size: 2.7vw;
  margin-bottom: 35px;
  word-wrap: nowrap;
  font-weight: bold;
}

.join-us-card.card > .card-body {
  font-weight: bold;
}

.join-us-card.card > div > .card-text {
  font-size: 16px;
  margin: 20px 0;
}

.location-separator {
  height: 5px;
}

.custom-container {
  max-width: 90% !important;
}

@media (max-width: 720px) {
  .card-block {
    padding: 80px 55px;
    text-align: center;
  }

  .card-title {
    margin-bottom: 35px !important;
  }

  .join-us-card.card > .card-body {
    font-size: 3.9vw;
    line-height: 1.3em;
  }

  .join-us-card.card > div > .card-title {
    font-size: 6.95vw;
  }

  .join-us-card.card > div > .card-text {
    font-size: 3.9vw;
    line-height: 1.3em;
  }

  .join-us-card.card {
    padding: 65px;
  }
}

@media (max-width: 520px) {
  .card-block {
    padding: 80px 35px;
  }

  .join-us-card.card {
    padding: 40px 30px;
  }
}

@media (min-width: 1920px) {
  .join-us-card.card > .card-body {
    font-size: 1.05vw;
  }

  .join-us-card.card > div > .card-title {
    font-size: 2.7vw;
  }

  .join-us-card.card > div > .card-text {
    font-size: 1.05vw;
  }

  .join-us-card.card {
    padding: 65px 40px;
  }

  .join-us-card.card > .card-body {
    padding-top: 20px;
  }

  .join-us-card.card > .card-img-top {
    height: 100px;
  }
}
