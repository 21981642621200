.mission-section-block {
  max-width: 100% !important;
  margin: 30px 0 9vw;
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.mission-section-block:last-child {
  margin-bottom: 100px;
}

.our-story-background {
  background-color: #2d9cdb;
  color: white;
}

.our-roots-background {
  background-color: #b7dcb1;
  color: #076268;
}

.reversed-section-image {
  align-self: flex-start;
}

.reversed-section > .second-section-part {
  justify-content: flex-end;
}

.reversed-purpose-section > .second-section-part {
  justify-content: flex-start;
}

.purpose-section-title {
  font-size: 2.7vw;
  font-weight: bold;
  margin-bottom: 10px;
}

.purpose-section-part {
  width: 44%;
}

.purpose-second-section-part {
  width: 44%;
}

.purpose-text {
  font-size: 16px;
}

.tc-foundation-background {
  background-color: #40c2f4;
  color: white;
}

.our-mission-block-text {
  font-size: 16px;
  margin-bottom: 1.24vw;
}

.our-mission-block-text:last-of-type {
  margin-bottom: 0;
}

.our-mission-title {
  margin-top: 60px;
  text-align: center;
}

@media (min-width: 721px) {
  .reversed-section, .reversed-purpose-section {
    flex-direction: row-reverse;
  }

  .reversed-section > .main-section-part {
    width: 40%;
    padding-right: 3%;
  }

  .reversed-section > .second-section-part {
    width: 50%;
    padding-right: 0;
    justify-content: flex-start;
  }

  .reversed-purpose-section > .main-section-part {
    width: 46%;
    padding-right: 1%;
  }

  .reversed-purpose-section > .second-section-part {
    width: 42%;
    padding-right: 0;
  }
}

@media (max-width: 720px) {
  .our-mission-title {
    margin: 80px 0;
  }

  .mission-section-block {
    margin: 0 0 50px;
  }

  .mission-section-block:last-child {
    margin-bottom: 0;
  } 

  .purpose-section-title {
    font-size: 10vw;
  }

  .purpose-text {
    font-size: 3.9vw;
  }

  .our-mission-block-text {
    font-size: 3.9vw;
    margin-bottom: 4.5vw;
  }

  .our-mission-block-title {
    font-size: 7.2vw;
  }

  .purpose-section-part {
    width: calc(100% - 40px);
  }
  
  .purpose-second-section-part {
    width: 100%;
  }
}

@media (min-width: 1920px) {
  .purpose-section-title {
    font-size: 2.7vw;
  }

  .purpose-text {
    font-size: 1.05vw;
  }

  .our-mission-block-text {
    font-size: 1.05vw;
  }
}

@media (min-width: 2560px) {
  .purpose-section-title {
    font-size: 2.5vw;
  }
}
