.carousel-container {
  margin-top: -90px;
  overflow: hidden;
  position: relative;

  background: #fff;

  height: 100px;
  width: 100%;
}

.carousel-card {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.carousel-card > img {
  height: 100%;
  width: 100%;

  aspect-ratio: 1/1;
  object-fit: contain;
}

.carousel-track {
  display: flex;
  position: absolute;
  left: 0;
  justify-content: center;
  align-items: center;
  gap: 16px;
  width: 200%;
  height: 100%;
  animation: slide 20s linear infinite;
  &:hover {
      animation-play-state: paused;
  }
}

@keyframes slide {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}

@media (max-width: 767px) {
  .carousel-track {
     width: 400%;
  }
}


@media (max-width: 612px) {
  .carousel-container {
    margin-top: -80px;
  }
}



