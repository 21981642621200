
.gm-ui-hover-effect {
  display: none !important;
}
  
.gm-style .gm-style-iw-c {
  border: 1px solid #1BA0DB;
}
  
.info-box {
  background-color: #fff;
  border-radius: 10px;
  
  text-align: center;
  
  padding: 20px 30px;
  
  width: 300px;
}
  
.info-box h3 {
  font-weight: normal;
  
  font-size: 1.5rem;
}
  
.info-box p {
  font-weight: lighter;
  font-size: 1rem;
}
  
.infoBox img {
  display: none;
}
  
.route-plan a {
  color: #1BA0DB;
  
  text-decoration: none;
  
  font-weight: normal;
}
  
.pl15 {
  padding-left: 15px;
}
  
.pr15 {
  padding-right: 15px;
}
  
@media only screen and (max-width: 768px)  {  
  .info-box {
    width:auto;
  
    top: auto;
    right: auto;
    bottom: 0;
  }
}
