.brand-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: white;
  padding: 1.3vw 6.8vw;
  -moz-box-shadow: 0 -10px 10px -10px #00000029;
  -webkit-box-shadow: 0 -10px 10px -10px #00000029;
  box-shadow: 0 -10px 10px -10px #00000029;
}

.mobile-brand-slider {
  height: 55.92vw !important;
}

.our-brands-carousel {
  .brand-bar {
    position: relative;
    box-shadow: none;
    margin-bottom: -30px
  }
  .nuka-container {
    height: 100px;
  }

  .desktop-home-brands {
    height: 100%;
    max-height: 100px;
  }

  .mobile-brand-slider {
    height: 100% !important;
    margin-bottom: -20px !important;
  }
}

.mobile-home-brands {
  display: none;
  height: auto;
  width: 100%;
  background-color: white;
  padding: 9.4vw 6.8vw;
  -moz-box-shadow: 0 -10px 10px -10px #00000029;
  -webkit-box-shadow: 0 -10px 10px -10px #00000029;
  box-shadow: 0 -10px 10px -10px #00000029;
}

.mobile-brand-slide {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 4vw;
}

.mobile-brand-logo {
  height: 10.89vw;
  width: 17.66vw;
  object-fit: contain;
}

.desktop-home-brands {
  height: 3.9vw;
}

.nuka-container {
  width: 90vw;
}

.nuka-container,
.nuka-overflow,
.nuka-wrapper,
.nuka-slide-container {
  height: 100%;
}

.desktop-home-brandbar-logo {
  width: 100%;
}

.home-brand-slider-arrow-container {
  position: absolute;
  top: 0;

  height: 100%;
  width: 100%;


  align-items: center;
  justify-content: space-between;
  display: flex;

  .home-brand-slider-left-arrow {
    margin-left: -4vw;   
  }

  .home-brand-slider-right-arrow {
    margin-right: -4vw;   
  }
}

@media (min-width: 1921px) {
  .desktop-home-brands {
    height: 120px;
  }
}

.our-brands-slider {
  .brand-logo {
    width: 400px;
    margin: 0 20px;
    aspect-ratio: 1/1;
    object-fit: contain;

    &:first-of-type {
      margin-left: 0;
    }
    &:last-of-type {
      margin-right: 0;
    }
  }
}

@media (min-width: 1920px) {
  .brand-bar {
    padding: 1.3vw 7.5vw;
  }
}

@media (max-width: 720px) {
  .desktop-home-brands {
    display: none;
  }

  .mobile-home-brands {
    display: block;
  }

  .brand-bar {
    display: none;
  }

  .mobile-home-brands > .slider > .slider-control-centerright {
    right: calc(-6.8vw + 20px) !important
  }

  .mobile-home-brands > .slider > .slider-control-centerleft {
    left: calc(-6.8vw + 20px) !important
  }

  .mobile-home-brands > .slider > .slider-control-centerleft > .slider-arrow {
    background-color: transparent;
  }

  .mobile-home-brands > .slider > .slider-control-centerright > .slider-arrow {
    background-color: transparent;
  }

  .home-brand-slider-left-arrow {
    padding-right: 5.55vw;
  }
  
  .home-brand-slider-right-arrow {
    padding-left: 5.55vw;
  }
}

@media (max-width: 620px) {
  .mobile-brand-logo {
    width: 18.66vw;
  }
}

@media (max-width: 400px) {
  .mobile-brand-logo {
    width: 15.66vw;
  }
}