.location-map-container {
  position: relative; 
}

.legend-container {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;  

  padding: 15px;
}

.retailer-selector-group {
  position: relative;
  width: auto;
    
  margin: 0 auto;
  padding: 15px;
    
  background-color: white;
  border-radius: 5px;
  border: 2px solid #bdbdbd;
}
  
.retailer-selector {
  display: flex;
  align-items: center;
  cursor: pointer;

  padding: 3px 0;
}

.retailer-selector-caret {
  margin-left: 8px;
  transition: transform 0.5s;
}

.retailer-selector-opener {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.retailer-color-badge {
  width: 15px;
  height: 15px;

  margin-right: 8px;
  
  border-radius: 50%;
}

.show-all-field {
  text-align: right;
  color: #bdbdbd;
}
