.success-page-content-wrapper {
  max-width: 700px;
  margin: 0 auto 2rem;
  padding: 3rem 0;
  text-align: center;
}

.success-paragraphs {
  margin: 1.45rem 2rem 0;
}

.success-paragraph {
  font-family: 'Nunito';
  font-weight: bold;
  white-space: pre-line;
}

.success-paragraph:last-child {
  margin-bottom: 0;
}

.success-image {
  border-bottom-left-radius: 35%;
  border-top-right-radius: 35%;
  max-width: 700px;
  margin: 0 1rem 3rem;
}
