.language-selector-image {
  margin-right: 5px;
}

.selected-language-image {
  height: 18px;
}

.selector-toggle { 
  line-height: 20px;
}

.btn-primary.dropdown-toggle {
  background-color: #1ca0db !important;
}

.loop-nav {
  box-shadow: 0px 3px 6px #00000029;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  width: 100vw;
}

.loop-nav.bg-light {
  background-color: #ffffff !important;
}

.navbar-collapse {
  justify-content: space-between;
}

.nav-button.btn.btn-primary {
  background-color: #1ca0db;
  border-color: #98a0a3;
}

.lng-selector {
  margin: auto;
  text-align: center;
}

.nav-button.btn.btn-primary:hover {
  background-color: #198fc5;
  border-color: #198fc5;
}

.nav-button.btn.btn-primary > a {
  color: #ffffff;
}

.nav-button.btn.btn-primary > a:hover {
  text-decoration: none;
}

.nav-link {
  color: #2d9cdb !important;
  font-weight: bold;
  font-size: 1rem;
}

.nav-link:hover {
  color: #065f86 !important;
}

.nav-link:last-child {
  border-right: none;
}

.nav-link > span {
  border-bottom: 2px solid transparent;
  margin-bottom: 0;
  white-space: nowrap;
}

.nav-link.selectedMenu span {
  border-bottom: 2px solid #2d9cdb;
}

.navbar-toggler {
  border: none !important;
}

.navbar-light > .navbar-toggler {
  padding: 0;
  outline: none;
}

.navbar-light > .navbar-toggler > .navbar-toggler-icon {
  color: #2d9cdb !important;
  border-color: #2d9cdb !important;
  background-image: url("../../images/close_icon.svg");
  font-size: 2rem;
  padding: 0;
}

.navbar-light > .navbar-toggler.collapsed {
  padding: 0.25rem 0.75rem;
}

.navbar-light > .navbar-toggler.collapsed > .navbar-toggler-icon {
  color: #2d9cdb !important;
  border-color: #2d9cdb !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgb%2845, 156, 219%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='3' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
  font-size: 1.25rem;
  padding: 0.25rem 0.75rem;
}

.navbar-logo {
  width: 6.46vw;
}

.navbar-brand {
  padding: 0 0 0 3vw !important;
}

.nav-link-above-720 {
  display: inline !important;
}

.nav-link-below-720{
  display: none !important;
}

@media (max-width: 991px) {
  .nav-link > span {
    border-bottom: none;
    font-size: 16px;
  }

  .nav-link.selectedMenu > span {
    border-bottom: none;
  }
}

@media (min-width: 721px) and (max-width: 767px) {
  .nav-link-above-720 {
    margin-bottom: 0rem !important;
  }
}

@media (max-width: 720px) {
  .loop-nav {
    box-shadow: none;
  }

  .navbar-brand {
    display: flex !important;
    align-items: center !important;
    height: 15vw !important;
    padding: 0 0 0 12px !important;
  }

  .navbar-logo {
    width: 100px;
  }

  .navbar-collapse {
    height: calc(100vh - 15vw) !important;
    margin: 0 -16px -16px;
    max-width: 100vw;
    background-color: #f0f0f0;
    display: flex;
    flex-direction: column;
  }

  .navbar-collapse > .navbar-nav {
    display: flex;
    width: 100%;
    height: 70%;
    padding: 8% 0 5%;
  }

  .navbar-collapse > .navbar-nav > .nav-link > span, .nav-link {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30%;
    font-size: 7.8vw;
    text-align: center;
    background-color: #f0f0f0;
  }

  .nav-link-above-720 {
    display: none !important;
  }

  .nav-link-below-720{
    display: inline !important;
  }
}

@media (max-width: 520px) {
  .navbar-brand {
    height: 80px !important;
  }

  .navbar-collapse {
    height: calc(100vh - 80px) !important;
  }
}

@media (min-width: 992px) {
  .nav-link {
    border-right: 1px solid #2d9cdb35;
    padding: 0 32px !important;
  }
}

@media (min-width: 1920px) {
  .nav-link {
    font-size: 1.25vw;
    padding: 0 42px !important;
  }

  .loop-nav {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
}
