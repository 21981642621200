.panel-wrapper {
  background: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 7rem 0;
}

.panel-heading {
  font-size: 1.75rem;
  text-align: center;
  margin: 0 2rem;
}

@media screen and (min-width: 600px) {
  .panel-heading {
    font-size: 2rem;
    margin: 0 7rem;
  }
}
