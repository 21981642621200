.error-page {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  height: 100vh;
  padding: 30px 0 150px;
}

@media (max-width: 720px) {
  .error-page {
    justify-content: flex-start;
  }
}

.error-page p {
  margin-bottom: 0;
  padding: 0 20px;
}

.error-page-title {
  font-size: 32px;
  font-weight: bold;
  padding: 0 20px;
}

.error-page-subtitle {
  font-size: 20px;
  line-height: 20px;
  font-weight: bold;
}

.error-image {
  max-height: 320px;
  width: auto;
  max-width: 90%;
  padding: 30px 20px;
}
