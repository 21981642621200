:root {
  --blue-20: #f3f8ff;
  --blue-100: #206af1;
  --blue-200: #022c44;
  --blue-100-02: rgba(32, 106, 241, .2);
  --blue-100-05: rgba(32, 106, 241, .5);
}

.loop-mobile-app-layout {
  .carousel-item p {
    margin-bottom: 50px;
  }

  img {
    max-width: 100% !important;
  }

  .carousel-indicators li {
    border: none;
  }

  .privacy-policy-page {
    p, table, div {
      color: var(--blue-200);
    }

    td, th {
      padding: 10px 20px;
    }

    td {
      font-weight: normal;
    }
  }

  .how-it-works-page {
    p {
      color: var(--blue-200);
    }

    p:has(img) {
      text-align: center;
    }

    img {
      max-height: 350px;
    }
  }

  .bg-loop {
    background-color: var(--blue-20);
    font-family: Nunito, Arial, Helvetica, sans-serif;
    font-size: 1.125rem;
    margin: 0;
    min-height: 100vh;
    padding: 20px 0;
  }

  .retailer-image {
    width: 100%;
  }

  .do-not-sell-page {
    p {
      color: var(--blue-200);
    }

    a {
      color: var(--blue-100) !important; 
    }
  }

  p {
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: normal;
  }

  .retailers-section-title {
    font-size: 3rem;
    color: var(--blue-100) !important;
  }


  h1, h2, h3, h4, h5, a {
    font-weight: bold;
    color: var(--blue-100) !important;
  }

  a > h1, a > h2, a > h3, a > h4, a > h5 {
    text-decoration: underline !important;
  }

  .half-image {
    p {
      display: flex;

      img {
        width: 50%;
      }
    }
  }

}

.color-white {
	color: white !important;
}

.loop-blue-color p {
  color: var(--blue-100);
}

.header-text {
  font-size: 1.5rem;
}

.block {
  margin-top: 20px;
}

.qr-image {
  padding: 10px;
  background-color: #fff;
  border-radius: 10px;
}

.app-review-image {
  border-radius: 30px;
  border: 1px solid var(--blue-100);
}

.block-text {
  margin-top: 20px;
}

.block-text h2 {
  font-size: 3rem;
}

.block-text p {
  font-size: 1.5rem;
  line-height: 2rem;
}

.first-description {
  font-size: 1.5rem;
  line-height: 2rem;
}

.support-box p {
  padding: 5px;
}

p, li, ol {
  color: var(--blue-200);
}

ol li::marker {
  color: var(--blue-100);
}

ul li {
  color: var(--blue-100);
  list-style: none
}

ul li::marker {
  color: var(--blue-200);
}

h1, h2, h3, h4, h5 {
  text-align: center;
  margin: 0;
}

h1 {
  font-size: 2rem;
  font-weight: 700;
  padding: 1rem 0 2rem;
}

h2 {
  font-size: 1.5rem;
  font-weight: 700;
  padding: 2rem 0 0;
  margin-bottom: 1rem;
}

h3 {
  font-size: 1.3rem;
  font-weight: 900;
}

h4 {
  font-size: 1.2rem;
  font-weight: 400;
}

h5 {
  font-size: 1.1rem;
  font-weight: 800;
}

.secondary-title {
  color: var(--blue-100);
  font-size: 18px;
  font-weight: 700;
}

a {
  text-decoration: none;
}

p {
  padding: 1rem 0;
  margin: 0;
}

.p18 {
  font-size: 18px !important;
}

ul {
  margin-top: 0;
}

.flex {
  display: flex;
}

.flex .email-btn {
  width: 100%;
}

.text-justify p {
  text-align: justify;
}

.text-justify h1,
.text-justify h2,
.text-justify h3,
.text-justify h4,
.text-justify h5 {
  text-align: left;
}

.underline {
  text-decoration: underline;
}

.us-privacy-policy {
  overflow: auto;
}

.us-privacy-policy p {
  text-align: left !important;
}

.us-privacy-policy ul li {
  list-style: circle;
}

.us-privacy-policy table {
  border-left: 1px solid gray;
  border-top: 1px solid gray;
  overflow: auto;
}

.us-privacy-policy table td,
.us-privacy-policy table th {
  border-right: 1px solid gray;
  padding-left: 10px;
}

.us-privacy-policy table td {
  border-bottom: 1px solid gray;
}

.us-privacy-policy table thead {
  border-bottom: 2px solid gray;
}

.logo {
  max-height: 100px;
}

.mobile-download-button {
  margin-top: 5rem;
  color: #fff;
  background-color: var(--blue-100);
  padding: 10px 15px;
  border-radius: 20px;
}

.mobile-download-button:hover {
  color: white;
  text-decoration: none;
}

.carousel-indicators {
  bottom: -30px;
}

.carousel-indicators li {
  background-color: transparent;
  border: 1px solid var(--blue-100);
  width: 10px;
  height: 10px;

  border-radius: 5px;
}

.carousel-indicators li.active {
  background-color: var(--blue-100);
}

.confirm-button {
  color: var(--blue-100);

  border: 1px solid var(--blue-100);
  background: transparent;

  font-size: 24px;

  padding: 20px 60px;
}

.card-wrapper {
  padding-top: 5px;
}

.card-wrapper h2 {
  font-weight: 800;
}

.faq-card, .faq-card-answer, .info-card {
  background-color: white;
  border-radius: 30px;
  box-shadow: 0 1rem 3rem var(--blue-100-02);
  padding: 30px;

}

.faq-card h2 {
  color: black;
  padding-bottom: 20px;
  text-align: left;
}

.faq-card .list-group {
  background-color: transparent;
}

.faq-card-question-list {
  padding-left: 0;
}

.faq-card-question-list li {
  color: black;
  padding: 10px 0;
  border-bottom: 1px solid var(--blue-100-02);
}

.faq-card-question-list li:has(+h3) {
  border: none;
}

.faq-card-question-list li p {
  padding-right: 5px;
  font-weight: 600;
}

.faq-card-question-list h3:first-of-type {
  margin-top: 0 !important;
}

.arrow {
  border: solid;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.faq-card-question-list li .arrow {
  border-color: var(--blue-100-05);
  height: 13px;
  width: 13px;
}

.faq-card-answer svg {
  margin-right: 10px;
}

.faq-card-answer h2 {
  text-align: left;
  color: black;
}

.loader {
  border: 6px solid var(--blue-20); /* Light grey */
  border-top: 6px solid var(--blue-100); /* Blue */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.btn-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 40px;
}

.btn-wrapper p {
  margin-bottom: 5px;
}

.email-btn {
  padding: 7px 20px;
  background-color: var(--blue-100);
  color: white;
  font-size: 1.5rem;
  border-radius: 30px;
}

.email-btn:hover {
  color: var(--blue-20);
}

.back-to-home {
  border-radius: 30px;
  font-weight: bold;
  color: var(--blue-100);
  font-size: 1rem;
  background-color: white;
  border: 1px solid var(--blue-100);
}

.back-to-home:hover,
.back-to-home:focus,
.back-to-home:active,
.back-to-home:active:focus {
  border: 1px solid var(--blue-100);
}

.card-footer {
  margin-top: 40px;
  font-weight: bold;
  flex-direction: column;
}

.card-footer a {
  margin-bottom: 10px;
}

.card-header {
  white-space: pre-line;  
  vertical-align: bottom;
}

@media only screen and (min-width: 1200px)  {
  .imageOffset {
    margin-top: -150px;  
  }
}

@media only screen and (max-width: 768px)  {
  html, body {
    overflow-x: hidden;
  }

  .first-description {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .header-text{
    font-size: 2rem;
  }

  .logo {
    max-height: 60px;
  }

  .return-image {
    max-width: 130%;
    margin-left: -15%;
    padding: 0;
  }

  .block-text h2 {
    font-size: 2rem;
  }

  .block-text p {
    font-size: 1rem;
    line-height: 1.5rem;
    text-align: left;
  }

  .download-box {
    margin-top: 100px;
  }
}
