.slider-arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.65vw;
  height: 3.65vw;
  border-radius: 50%;
  background-color: #ffffff;
  cursor: pointer;
}

.arrow {
  height: 1.67vw;
  color: #1ca0db !important;
}

.left-arrow {
  padding-right: 4px;
}

.right-arrow {
  padding-left: 4px;
}

.arrow-with-border {
  box-shadow: 0px 3px 6px #00000029 !important;
}

.arrow-with-border:hover {
  box-shadow: 0px 5px 8px #00000029 !important;
}

.expendable-arrow {
  margin: 40px auto 0;
  background-color: #1ca0db !important;
}

.expendable-arrow > .arrow {
  color: #ffffff !important;
}

.up-arrow {
  margin-bottom: 5px;
}

@media (max-width: 1000px) {
  .arrow {
    height: 28px;
  }

  .slider-arrow {
    height: 6vw;
    width: 6vw;
  }
}

@media (max-width: 720px) {
  .arrow {
    height: 6vw;
  }

  .slider-arrow {
    height: 10.41vw;
    width: 10.41vw;
  }

  .expendable-arrow {
    margin-top: 0;
  }
}

@media (min-width: 1920px) {
  .slider-arrow {
    font-size: 40px;
  }

  .slider-arrow-image {
    height: 32px;
  }
}
