.retailer-pin {
  cursor: pointer;
  opacity: 0.9;
  transition: opacity 0.3s;
}

.retailer-pin:hover {
  opacity: 1;
}

.retailer-map-container {
  height: 100%;
  position: relative;
  width: 100%;
}

.retailer-map-logo-container {
  position: absolute !important;
  top: 0;
  left: 0;
  z-index: 2;
}

.haagen-dazs-container {
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(0.6);
}

@media (min-width: 721px) {
  .haagen-dazs-container {
    display: none;
  }
}
