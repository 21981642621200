svg {
  position: initial !important;
}

html {
  font-size: 16px;
  overflow-x: hidden;
}

.cleaning {
  background: #b7dcb1 0% 0% no-repeat padding-box;
  color: #076268;
}

.ecosystem {
  background-color: #1ca0db;
  color: #ffffff;
}

.main-section-background {
  display: flex;
  align-items: center;
  min-height: calc(100vh - 4.8vw);
}

@media (max-width: 767px) {
  .main-section-background {
    height: auto;
    min-height: calc(100vh - 112px);
  }
}

.press-section-background {
  background: #1ca0db 0% 0% no-repeat padding-box;
}

.main-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 55px 0;
  background-size: cover;
}

.main-section-content {
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 -20px;
}

.main-section-part {
  width: 36.5%;
  min-width: 265px;
  margin: 0 20px;
}

.main-section-title {
  font-size: 2.7vw;
  font-weight: bold;
  line-height: 1.1em;
  margin-bottom: 25px;
}

.primary-title {
  padding-right: 3vw;
  font-size: 3.75vw;
}

.main-section-subtitle {
  font-size: 1.7vw;
  font-weight: bold;
  line-height: 1.3em;
  margin-bottom: 5px;
}

.main-section-text {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 22px;
}

.second-section-part {
  display: flex;
  justify-content: flex-end;
  width: 50%;
}

.primary-text {
  font-size: 16px;
  color: #4f4f4f;
}

.press-section {
  min-height: calc(100vh - 4.8vw);
  width: 100%;
  background-image: url("../../images/home/press-logos-background.png");
  background-color: rgba(28, 160, 219, 0.39);
}

.press-section-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  max-width: 55vw !important;
}

.press-section-inner-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 55px;
  padding-bottom: 55px;
  text-align: center;
  color: #ffffff;
  background: radial-gradient(
    #1ca0db 60%,
    rgba(28, 160, 219, 0.39) 95%,
    transparent 100%
  );
}

.press-section-quote {
  font-size: 2.8vw;
  line-height: 1.1em;
  font-weight: bold;
  margin-bottom: 20px;
}

.press-section-source {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 50px;
}

.btn {
  width: auto;
  min-width: 180px;
  padding: 0.6vw 1.5vw;
  font-weight: bold;
  text-decoration: none;
  border: none;
}

.btn:hover {
  text-decoration: none;
}

.main-button.btn.btn-primary {
  background-color: #1ca0db;
  border-color: #98a0a3;
}

.main-button.btn.btn-primary:hover {
  background-color: #198fc5;
  border-color: #198fc5;
}

.main-button.btn.btn-primary > a {
  color: #ffffff;
}

.main-button.btn.btn-primary > a:hover {
  text-decoration: none;
}

.btn.btn-light {
  color: #1ca0db;
}

.btn.btn-light > a {
  color: #1ca0db;
}

.btn.btn-light > a:hover {
  text-decoration: none;
}

.section-image {
  max-width: 100%;
  object-fit: cover;
  align-self: flex-end;
}

.home-shop-section {
  padding-bottom: 120px;
}

.fast-company-logo {
  width: 15.4vw;
  margin: 0.52vw 0 1.56vw;
}

.text-align-left {
  text-align: left;
}

.text-align-center {
  text-align: center;
}

.text-align-right {
  text-align: right;
}

@media (max-width: 1200px) {
  .section-image {
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
  }
}

@media (max-width: 767px) {
  .purpose-section-image {
    width: 60vw;
    height: 60vw;
    align-self: center;
    margin-top: 30px;
  }
}

.shop-section-background {
  background-image: url("../../images/home/banner-image.jpg");
  background-repeat: no-repeat;
  background-position: 215% 50%;
  background-size: 90%;
  flex-direction: column;
  justify-content: center;
  position: relative;
  max-height: 800px;
}

.shop-section-image {
  display: none;
}

@media (min-width: 721px) {
  .mobile-cleaning-image {
    display: none !important;
  }
}

@media (max-width: 720px) {
  .btn {
    padding: 13px 45px;
  }

  #animation-helper-3 {
    display: none;
  }

  .mobile-cleaning-image {
    display: block;
  }

  .home-shop-section {
    padding-bottom: 0;
  }

  .main-section {
    padding: 80px 0 40px;
  }

  .main-section-title {
    font-size: 10vw;
  }

  .main-section-subtitle {
    font-size: 4.5vw;
  }

  .main-section-text {
    font-size: 3.9vw
  }

  .main-section-content {
    flex-direction: column;
  }

  .main-section-part {
    width: calc(100% - 40px);
    align-items: center;
    text-align: center;
  }

  .main-section-part > img {
    width: 100%;
    height: auto;
  }

  .container-lg {
    max-width: 100% !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .shop-section-image {
    display: block;
    width: 100%;
    margin-top: 30px;
  }

  .padded-image-content {
    padding: 0 40px;
  }

  .padded-text-content {
    padding: 0 20px;
  }

  .shop-section {
    padding-bottom: 0;
  }

  .shop-section-background {
    background-image: none;
  }

  .primary-title {
    padding-right: 0;
    word-break: break-word;
    font-size: 10vw;
  }

  .primary-text {
    font-size: 3.9vw;
  }

  .first-page-section {
    padding-top: 15px;
  }

  .second-section-part {
    padding-top: 30px;
    margin: 0;
    width: 100%;
  }

  .press-section-quote {
    font-size: 7.5vw;
    line-height: 8.3vw;
  }
  
  .press-section-source {
    font-size: 2.8vw;
  }

  .shop-section-title {
    padding: 0 6.95vw;
  }

  .fast-company-logo {
    width: 45vw;
    margin: 1vw 0 6vw;
  }
}

@media (min-width: 1920px) {
  .btn {
    font-size: 0.85vw;
    padding: 0.6vw 1.5vw;
    border-radius: 0.21vw; 
  }

  .main-section-background {
    min-height: calc(100vh - 4.5vw);
  }
  
  .main-section-title {
    font-size: 2.7vw;
  }

  .main-section-subtitle {
    font-size: 1.7vw;
  }

  .main-section-text {
    font-size: 1.15vw;
  }

  .primary-title {
    font-size: 3.75vw;
  }

  .primary-text {
    font-size: 1.05vw;
  }

  .press-section {
    min-height: calc(100vh - 97px);
  }
  
  .press-section-source {
    font-size: 1.05vw;
  }

  .section-image {
    max-height: unset;
    max-width: unset;
    height: auto;
    width: 100%;
  }

  .animation-image-container {
    max-width: 100%;
    width: unset;
    height: unset;
  }

  .animation-image {
    width: unset;
    height: unset;
  }
}

@media (min-width: 2560px) {
  .section-image {
    max-width: 100%;
    height: auto;
  }

  .main-section-title {
    font-size: 2.5vw;
  }

  .primary-title {
    font-size: 3.75;
  }

  .fast-company-logo {
    margin-top: 1.56vw;
  }
}

.animation-element {
  position: absolute;
  z-index: 10;
}

.second-animation-elements {
  visibility: hidden;
}

.relative {
  position: relative;
}

.animated-image-helper {
  transform: rotate(10deg);
}