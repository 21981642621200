.st0 {
  fill: url(#Path_7753_3_);
}
.st1 {
  fill: #ffffff;
}
.st2 {
  clip-path: url(#SVGID_2_);
}
.st3 {
  clip-path: url(#SVGID_4_);
}
.st4 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #0054a4;
}
.st5 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #f02e25;
}
.st6 {
  fill: url(#Path_7753-2_5_);
}
.st7 {
  fill: #dc1b32;
}
.st8 {
  fill: url(#Path_7753-2_6_);
}
.st9 {
  fill: #c8102f;
}
.st10 {
  fill: url(#Path_7753-3_3_);
}
.st11 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #97326c;
}
.st12 {
  fill: url(#Path_7753-4_3_);
}
.st13 {
  fill: #13428b;
}
.st14 {
  fill: url(#Path_7753-6_3_);
}
.st15 {
  fill: #de0e1a;
}
.st16 {
  fill: #014591;
}
.st17 {
  fill: url(#Path_7753-7_3_);
}
.st18 {
  fill: #00542c;
}
.st19 {
  clip-path: url(#SVGID_6_);
}
.st20 {
  clip-path: url(#SVGID_8_);
}
.st21 {
  fill: #14893c;
}
.st22 {
  fill: #16893c;
}
.st23 {
  fill: #188a3c;
}
.st24 {
  fill: #1a8a3c;
}
.st25 {
  fill: #1c8b3c;
}
.st26 {
  fill: #1e8b3c;
}
.st27 {
  fill: #208c3c;
}
.st28 {
  fill: #228c3c;
}
.st29 {
  fill: #248d3c;
}
.st30 {
  fill: #258d3c;
}
.st31 {
  fill: #278e3c;
}
.st32 {
  fill: #288e3d;
}
.st33 {
  fill: #2a8f3d;
}
.st34 {
  fill: #2c8f3d;
}
.st35 {
  fill: #2d903d;
}
.st36 {
  fill: #2f903d;
}
.st37 {
  fill: #30903d;
}
.st38 {
  fill: #32913d;
}
.st39 {
  fill: #33913d;
}
.st40 {
  fill: #35923d;
}
.st41 {
  fill: #36923d;
}
.st42 {
  fill: #37933d;
}
.st43 {
  fill: #39933d;
}
.st44 {
  fill: #3a943d;
}
.st45 {
  fill: #3c943d;
}
.st46 {
  fill: #3d953d;
}
.st47 {
  fill: #3e953d;
}
.st48 {
  fill: #40963d;
}
.st49 {
  fill: #41963d;
}
.st50 {
  fill: #42973d;
}
.st51 {
  fill: #44973d;
}
.st52 {
  fill: #45973d;
}
.st53 {
  fill: #46983d;
}
.st54 {
  fill: #47983d;
}
.st55 {
  fill: #49993d;
}
.st56 {
  fill: #4a993d;
}
.st57 {
  fill: #4b9a3d;
}
.st58 {
  fill: #4c9a3d;
}
.st59 {
  fill: #4d9b3d;
}
.st60 {
  fill: #4f9b3d;
}
.st61 {
  fill: #509c3d;
}
.st62 {
  fill: #519d3d;
}
.st63 {
  fill: #529d3d;
}
.st64 {
  fill: #539e3d;
}
.st65 {
  fill: #559e3d;
}
.st66 {
  fill: #569f3d;
}
.st67 {
  fill: #579f3d;
}
.st68 {
  fill: #58a03d;
}
.st69 {
  fill: #59a03d;
}
.st70 {
  fill: #5ba13d;
}
.st71 {
  fill: #5ca13d;
}
.st72 {
  fill: #5da23d;
}
.st73 {
  fill: #5ea23d;
}
.st74 {
  fill: #5fa33d;
}
.st75 {
  fill: #60a33d;
}
.st76 {
  fill: #62a43d;
}
.st77 {
  fill: #63a43d;
}
.st78 {
  fill: #64a53d;
}
.st79 {
  fill: #65a53d;
}
.st80 {
  fill: #66a63e;
}
.st81 {
  fill: #67a63e;
}
.st82 {
  fill: #68a73e;
}
.st83 {
  fill: #6aa73e;
}
.st84 {
  fill: #6ba83e;
}
.st85 {
  fill: #6ca83e;
}
.st86 {
  fill: #6da93e;
}
.st87 {
  fill: #6ea93e;
}
.st88 {
  fill: #6faa3e;
}
.st89 {
  fill: #70aa3e;
}
.st90 {
  fill: #71ab3e;
}
.st91 {
  fill: #72ab3e;
}
.st92 {
  fill: #73ac3e;
}
.st93 {
  fill: #75ac3e;
}
.st94 {
  fill: #76ad3e;
}
.st95 {
  fill: #77ad3e;
}
.st96 {
  fill: #78ae3e;
}
.st97 {
  fill: #79ae3e;
}
.st98 {
  fill: #7aaf3e;
}
.st99 {
  fill: #7baf3e;
}
.st100 {
  fill: #7cb03e;
}
.st101 {
  fill: #7db03e;
}
.st102 {
  fill: #7eb13e;
}
.st103 {
  fill: #7fb13e;
}
.st104 {
  fill: #80b23e;
}
.st105 {
  fill: #82b23e;
}
.st106 {
  fill: #83b33e;
}
.st107 {
  fill: #84b43e;
}
.st108 {
  fill: #85b43e;
}
.st109 {
  fill: #86b53e;
}
.st110 {
  fill: #87b53e;
}
.st111 {
  fill: #88b63e;
}
.st112 {
  fill: #89b63e;
}
.st113 {
  fill: #8ab73e;
}
.st114 {
  fill: #8bb73e;
}
.st115 {
  fill: #8db83e;
}
.st116 {
  fill: #8eb83e;
}
.st117 {
  fill: #8fb93e;
}
.st118 {
  fill: #90b93e;
}
.st119 {
  fill: #91ba3e;
}
.st120 {
  fill: #92ba3e;
}
.st121 {
  fill: #93bb3e;
}
.st122 {
  fill: #94bb3e;
}
.st123 {
  fill: #95bc3e;
}
.st124 {
  fill: #96bd3e;
}
.st125 {
  fill: #97bd3e;
}
.st126 {
  fill: #98be3e;
}
.st127 {
  fill: #99be3e;
}
.st128 {
  fill: #9abf3e;
}
.st129 {
  fill: #9bbf3e;
}
.st130 {
  fill: #9dc03e;
}
.st131 {
  fill: #9ec03e;
}
.st132 {
  fill: #9fc13e;
}
.st133 {
  fill: #a0c13e;
}
.st134 {
  fill: #a1c23e;
}
.st135 {
  fill: #a2c33e;
}
.st136 {
  fill: #a3c33e;
}
.st137 {
  fill: #a4c43e;
}
.st138 {
  fill: #a5c43e;
}
.st139 {
  fill: #a6c53e;
}
.st140 {
  fill: #a7c63e;
}
.st141 {
  fill: #a8c63e;
}
.st142 {
  fill: #a9c73e;
}
.st143 {
  fill: #aac73e;
}
.st144 {
  fill: #abc83e;
}
.st145 {
  fill: #acc83e;
}
.st146 {
  fill: #adc93e;
}
.st147 {
  fill: #aec93e;
}
.st148 {
  fill: #aeca3e;
}
.st149 {
  clip-path: url(#SVGID_10_);
}
.st150 {
  clip-path: url(#SVGID_12_);
}
.st151 {
  fill: #68ac3b;
}
.st152 {
  fill: #69ac3b;
}
.st153 {
  fill: #6aad3b;
}
.st154 {
  fill: #6cad3b;
}
.st155 {
  fill: #6dae3b;
}
.st156 {
  fill: #6fae3b;
}
.st157 {
  fill: #70af3b;
}
.st158 {
  fill: #71af3b;
}
.st159 {
  fill: #72b03b;
}
.st160 {
  fill: #74b03c;
}
.st161 {
  fill: #75b13c;
}
.st162 {
  fill: #76b13c;
}
.st163 {
  fill: #77b23c;
}
.st164 {
  fill: #79b23c;
}
.st165 {
  fill: #7ab33c;
}
.st166 {
  fill: #7bb33c;
}
.st167 {
  fill: #7cb43c;
}
.st168 {
  fill: #7db43c;
}
.st169 {
  fill: #7fb53c;
}
.st170 {
  fill: #80b53c;
}
.st171 {
  fill: #81b63c;
}
.st172 {
  fill: #82b63c;
}
.st173 {
  fill: #83b73c;
}
.st174 {
  fill: #84b73c;
}
.st175 {
  fill: #86b73c;
}
.st176 {
  fill: #87b83c;
}
.st177 {
  fill: #88b83c;
}
.st178 {
  fill: #89b93c;
}
.st179 {
  fill: #8ab93c;
}
.st180 {
  fill: #8bba3c;
}
.st181 {
  fill: #8cba3c;
}
.st182 {
  fill: #8dbb3c;
}
.st183 {
  fill: #8ebb3c;
}
.st184 {
  fill: #8fbc3d;
}
.st185 {
  fill: #90bc3d;
}
.st186 {
  fill: #91bc3d;
}
.st187 {
  fill: #92bd3d;
}
.st188 {
  fill: #93bd3d;
}
.st189 {
  fill: #94be3d;
}
.st190 {
  fill: #95be3d;
}
.st191 {
  fill: #96bf3d;
}
.st192 {
  fill: #97bf3d;
}
.st193 {
  fill: #98bf3d;
}
.st194 {
  fill: #99c03d;
}
.st195 {
  fill: #9ac03d;
}
.st196 {
  fill: #9bc13d;
}
.st197 {
  fill: #9cc13d;
}
.st198 {
  fill: #9dc23d;
}
.st199 {
  fill: #9ec23d;
}
.st200 {
  fill: #9fc33d;
}
.st201 {
  fill: #a0c33d;
}
.st202 {
  fill: #a1c43d;
}
.st203 {
  fill: #a2c43d;
}
.st204 {
  fill: #a3c43d;
}
.st205 {
  fill: #a3c53d;
}
.st206 {
  fill: #a4c53d;
}
.st207 {
  fill: #a5c53d;
}
.st208 {
  fill: #a5c63d;
}
.st209 {
  fill: #a6c63d;
}
.st210 {
  fill: #a8c73e;
}
.st211 {
  fill: #aac83e;
}
.st212 {
  fill: #acc93e;
}
.st213 {
  clip-path: url(#SVGID_14_);
}
.st214 {
  clip-path: url(#SVGID_16_);
}
.st215 {
  clip-path: url(#SVGID_18_);
}
.st216 {
  clip-path: url(#SVGID_20_);
}
.st217 {
  fill: #178a3c;
}
.st218 {
  fill: #198a3c;
}
.st219 {
  fill: #1d8b3c;
}
.st220 {
  fill: #1f8b3c;
}
.st221 {
  fill: #238d3c;
}
.st222 {
  fill: #268d3c;
}
.st223 {
  fill: #298e3d;
}
.st224 {
  fill: #2b8f3d;
}
.st225 {
  fill: #2e903d;
}
.st226 {
  fill: #31913d;
}
.st227 {
  fill: #34923d;
}
.st228 {
  fill: #38933d;
}
.st229 {
  fill: #3b943d;
}
.st230 {
  fill: #3f953d;
}
.st231 {
  fill: #48993d;
}
.st232 {
  fill: #4a9a3d;
}
.st233 {
  fill: #4e9b3d;
}
.st234 {
  fill: #519c3d;
}
.st235 {
  fill: #539d3d;
}
.st236 {
  fill: #549e3d;
}
.st237 {
  fill: #5aa13d;
}
.st238 {
  fill: #5fa23d;
}
.st239 {
  fill: #61a33d;
}
.st240 {
  fill: #65a53e;
}
.st241 {
  fill: #68a63e;
}
.st242 {
  fill: #69a73e;
}
.st243 {
  fill: #74ac3e;
}
.st244 {
  fill: #81b23e;
}
.st245 {
  fill: #82b33e;
}
.st246 {
  fill: #86b43e;
}
.st247 {
  fill: #88b53e;
}
.st248 {
  fill: #8cb73e;
}
.st249 {
  fill: #90ba3e;
}
.st250 {
  fill: #98bd3e;
}
.st251 {
  fill: #9cbf3e;
}
.st252 {
  fill: #9ec13e;
}
.st253 {
  fill: #a7c53e;
}
.st254 {
  clip-path: url(#SVGID_22_);
}
.st255 {
  clip-path: url(#SVGID_24_);
}
.st256 {
  fill: #218c3c;
}
.st257 {
  fill: #2d8f3d;
}
.st258 {
  fill: #42963d;
}
.st259 {
  fill: #43973d;
}
.st260 {
  fill: #4f9c3d;
}
.st261 {
  fill: #5aa03d;
}
.st262 {
  fill: #66a53e;
}
.st263 {
  fill: #71aa3e;
}
.st264 {
  fill: #75ad3e;
}
.st265 {
  fill: #94bc3e;
}
.st266 {
  fill: #a0c23e;
}
.st267 {
  clip-path: url(#SVGID_26_);
}
.st268 {
  clip-path: url(#SVGID_28_);
}
.st269 {
  fill: #5ba535;
}
.st270 {
  fill: #5ca535;
}
.st271 {
  fill: #5da535;
}
.st272 {
  fill: #5fa635;
}
.st273 {
  fill: #60a635;
}
.st274 {
  fill: #61a735;
}
.st275 {
  fill: #62a735;
}
.st276 {
  fill: #63a835;
}
.st277 {
  fill: #64a835;
}
.st278 {
  fill: #65a835;
}
.st279 {
  fill: #66a936;
}
.st280 {
  fill: #68a936;
}
.st281 {
  fill: #69aa36;
}
.st282 {
  fill: #6aaa36;
}
.st283 {
  fill: #6bab36;
}
.st284 {
  fill: #6cab36;
}
.st285 {
  fill: #6dab36;
}
.st286 {
  fill: #6eac36;
}
.st287 {
  fill: #6fac36;
}
.st288 {
  fill: #71ad37;
}
.st289 {
  fill: #72ad37;
}
.st290 {
  fill: #73ae37;
}
.st291 {
  fill: #74ae37;
}
.st292 {
  fill: #75af37;
}
.st293 {
  fill: #76af37;
}
.st294 {
  fill: #77b037;
}
.st295 {
  fill: #78b037;
}
.st296 {
  fill: #79b138;
}
.st297 {
  fill: #7ab138;
}
.st298 {
  fill: #7bb238;
}
.st299 {
  fill: #7cb238;
}
.st300 {
  fill: #7db238;
}
.st301 {
  fill: #7eb338;
}
.st302 {
  fill: #80b338;
}
.st303 {
  fill: #81b438;
}
.st304 {
  fill: #82b438;
}
.st305 {
  fill: #83b539;
}
.st306 {
  fill: #84b539;
}
.st307 {
  fill: #85b639;
}
.st308 {
  fill: #86b639;
}
.st309 {
  fill: #87b739;
}
.st310 {
  fill: #88b739;
}
.st311 {
  fill: #89b839;
}
.st312 {
  fill: #8bb839;
}
.st313 {
  fill: #8cb939;
}
.st314 {
  fill: #8db93a;
}
.st315 {
  fill: #8eba3a;
}
.st316 {
  fill: #8fba3a;
}
.st317 {
  fill: #90bb3a;
}
.st318 {
  fill: #91bb3a;
}
.st319 {
  fill: #92bc3a;
}
.st320 {
  fill: #94bc3a;
}
.st321 {
  fill: #95bd3a;
}
.st322 {
  fill: #96bd3b;
}
.st323 {
  fill: #97be3b;
}
.st324 {
  fill: #98be3b;
}
.st325 {
  fill: #99bf3b;
}
.st326 {
  fill: #9ac03b;
}
.st327 {
  fill: #9bc03b;
}
.st328 {
  fill: #9cc13b;
}
.st329 {
  fill: #9ec13c;
}
.st330 {
  fill: #9fc23c;
}
.st331 {
  fill: #a0c23c;
}
.st332 {
  fill: #a1c33c;
}
.st333 {
  fill: #a2c43c;
}
.st334 {
  fill: #a3c43c;
}
.st335 {
  fill: #a6c53d;
}
.st336 {
  fill: #a7c63d;
}
.st337 {
  fill: #a8c63d;
}
.st338 {
  fill: #a9c73d;
}
.st339 {
  fill: #aac83d;
}
.st340 {
  fill: #abc83d;
}
.st341 {
  fill: url(#Path_7753-8_3_);
}
.st342 {
  clip-path: url(#SVGID_30_);
}
.st343 {
  clip-path: url(#SVGID_32_);
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #ee3124;
}
.st344 {
  clip-path: url(#SVGID_32_);
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #f78e1e;
}
.st345 {
  clip-path: url(#SVGID_32_);
}
.st346 {
  clip-path: url(#SVGID_32_);
  fill-rule: evenodd;
  clip-rule: evenodd;
}
.st347 {
  fill: url(#Path_7753-10_2_);
}
.st348 {
  fill: #5a5758;
}
.st349 {
  fill: url(#Path_7753-12_3_);
}
.st350 {
  fill: #f5ebdc;
}
.st351 {
  fill: #ff8732;
}
.st352 {
  fill: #d62300;
}
.st353 {
  fill: url(#Path_7753-13_3_);
}
.st354 {
  clip-path: url(#SVGID_34_);
}
.st355 {
  fill: #ffbc0d;
}
.st356 {
  fill: url(#Path_7753-5_3_);
}
.st357 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #004b8d;
}
.st358 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #fff200;
}
.st359 {
  fill: url(#Path_7753_4_);
}
.st360 {
  clip-path: url(#SVGID_36_);
}
.st361 {
  clip-path: url(#SVGID_38_);
}
.st362 {
  fill: url(#Path_7753-2_7_);
}
.st363 {
  fill: url(#Path_7753-2_8_);
}
.st364 {
  fill: url(#Path_7753-3_4_);
}
.st365 {
  fill: url(#Path_7753-4_4_);
}
.st366 {
  fill: url(#Path_7753-6_4_);
}
.st367 {
  fill: url(#Path_7753-7_4_);
}
.st368 {
  clip-path: url(#SVGID_40_);
}
.st369 {
  clip-path: url(#SVGID_42_);
}
.st370 {
  clip-path: url(#SVGID_44_);
}
.st371 {
  clip-path: url(#SVGID_46_);
}
.st372 {
  clip-path: url(#SVGID_48_);
}
.st373 {
  clip-path: url(#SVGID_50_);
}
.st374 {
  clip-path: url(#SVGID_52_);
}
.st375 {
  clip-path: url(#SVGID_54_);
}
.st376 {
  clip-path: url(#SVGID_56_);
}
.st377 {
  clip-path: url(#SVGID_58_);
}
.st378 {
  clip-path: url(#SVGID_60_);
}
.st379 {
  clip-path: url(#SVGID_62_);
}
.st380 {
  fill: url(#Path_7753-8_4_);
}
.st381 {
  clip-path: url(#SVGID_64_);
}
.st382 {
  clip-path: url(#SVGID_66_);
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #ee3124;
}
.st383 {
  clip-path: url(#SVGID_66_);
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #f78e1e;
}
.st384 {
  clip-path: url(#SVGID_66_);
}
.st385 {
  clip-path: url(#SVGID_66_);
  fill-rule: evenodd;
  clip-rule: evenodd;
}
.st386 {
  fill: url(#Path_7753-10_3_);
}
.st387 {
  fill: url(#Path_7753-12_4_);
}
.st388 {
  fill: url(#Path_7753-13_4_);
}
.st389 {
  clip-path: url(#SVGID_68_);
}
.st390 {
  fill: url(#Path_7753-5_4_);
}
