.form-page {
  color: #4F4F4F;
}

.form-page > h1, h2, p, form {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
}

.form-page > h1, h2 {
  text-rendering: optimizeLegibility;
  font-weight: bold;
}

.form-page > h1 {
  font-size: 2.5rem;
  line-height: 1.1;
}

.form-page > h2 {
  font-size: 2.25rem;
  line-height: 1.1;
}

.form-page > b, strong {
  font-weight: bold;
}

.form-container {
  margin: 0 1rem;
}

.form-wrapper {
  width: 100%;
  max-width: 516px;
  margin: 0 auto;
}

.form-paragraphs-wrapper {
  margin: 1rem 0 2.5rem 0;
  text-align: center;
}

.form-paragraphs-wrapper p {
  margin-bottom: .3rem;
}

.form-text-input-field, .form-input-label, .form-checkbox-wrapper, .form-select-field {
  width: 100%;
  display: block;
}

.form-text-input-wrapper {
  margin: 1.15rem 0;
}

.form-text-input-wrapper:first-child {
  margin-top: 0;
}

.form-input-label {
  line-height: normal;
  font-size: 1rem;
  margin-bottom: .3rem;
}

.form-checkbox-wrapper {
  margin: 1.15rem 0;
  display: flex;
  align-items: center;
}

.form-checkbox-input {
  flex-basis: 20px;
}

.form-captcha-wrapper {
  margin: 1.15rem 0;
  display: flex;
  align-items: center;
}

.form-captcha-wrapper.error-border .g-recaptcha {
  border:1px solid red;
}

.form-checkbox-label {
  flex-basis: 100%;
  font-size: 1rem;
  color: #828282;
  margin-bottom: 0;
  margin-left: 12px;
}

.form-checkbox-label p {
  margin-bottom: 0;
}

.form-checkbox-label > p > a {
  color: #2d9cdb !important;
  font-weight: bold;
  font-size: 16px;
}

.form-checkbox-label > p > a:hover {
  color: #065f86 !important;
}

.form-paragraphs-wrapper p:first-child {
  font-weight: bold;
}

.form-text-input-field, .form-select-field {
  background: #FFFFFF;
  border: 1px solid #BDBDBD;
  border-radius: 4px;
  transition: border 0.2s linear;
  outline: none;
  -moz-appearane: none;
  -webkit-appearance: none;
  padding: .3rem .5rem;
  font-size: 18px;
  height: 2.2em;
  box-sizing: border-box;
}

.form-text-input-field:focus, .form-select-field:focus {
  border: 2px solid #4CBCFD;
}

.form-text-input-field.error, .form-select-field.error {
  border-color: #FF2020;
}

.form-error-message {
  color: #FF2020;
}

.form-submit-button {
  line-height: normal;
  font-size: 1.1rem;
  text-align: center;
  color: #FFF;
  background-color: #4CBCFD;
  border-radius: 4px;
  padding: .6rem;
  width: 100%;
  outline-color: #4CBCFD;
  transition: background-color .3s;
}

.form-submit-button:hover {
  cursor: pointer;
  background-color: #2D9CDB;
}

.form-footer-wrapper {
  display: flex;
  flex-direction: column;
  background-color: #FFF;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0;
  padding: 0 0 7rem;
  position: relative;
}

.form-contact {
  margin: 0 auto;
  overflow: hidden;
}

.form-contact ul {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  margin: 0 0 0 -1px;
  padding: 0;
}

.form-contact ul li {
  font-size: .9rem;
  margin: 0;
  border-left: 1px solid #BDBDBD;
  flex-grow: 1;
  flex-basis: auto;
  padding: 0 .8rem;
  text-align: center;
}

.form-contact ul li:last-child:after {
  content: none;
}
