.news-section {
  margin-top: 60px;
  margin-bottom: 100px;
  text-align: center;
}

.press-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 185px;
  padding-bottom: 40px;
}

.press-search-button {
  flex: 1;
  height: 50px;
  margin: 0 12px;
}

@media (min-width: 992px) {
  .press-search-button {
    max-width: 32%;
  }
}

@media (max-width: 535px) {
  .press-search-button {
    min-width: 100%;
  }
}

.press-search-field {
  flex: 1;
  margin: 0 12px;
  display: flex;
  flex-direction: column;
}

.press-search-field > .form-label {
  font-size: 16px;
  font-weight: bold;
}

.search-section {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 0 -12px 95px;
  flex-wrap: wrap;
}

.form-control.press-form-control {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  
  height: 60px;
  min-width: 240px;
  margin-bottom: 20px;
  padding: 15px;
  box-sizing: border-box;
  border: 2px solid #bdbdbd;
  border-radius: 4px;
  font-size: 21px;
  font-weight: 300;
  color: #4f4f4f;
  background-color: transparent;
}

.highlighted-news {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, auto));
  margin: 45px -20px 80px;
}

.press-highlighted-item {
  word-wrap: break-word;
  min-width: 240px;
  margin: 0 20px 40px;
  text-align: left;
}

.press-highlighted-item-content {
  font-weight: 300;
  color: #4f4f4f;
  margin-top: 10px;
}

.press-highlighted-item-content,
.continue-reading {
  font-size: 16px;
}

.additional-items {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(37.5%, 1fr));
  grid-gap: 20px;
}

.press-additional-item {
  text-align: left;
  margin-bottom: 45px;
}

.item-title {
  font-size: 20px;
  word-wrap: break-word;
}

.press-item-date {
  font-size: 16px;
  color: #4f4f4f;
  margin-top: 25px;
}

.additional-item-content {
  display: none;
}

.blog-post-container {
  margin-top: 45px;
  margin-bottom: 300px;
}

.press-page-link {
  color: #1ca0db;
  text-decoration: none;
  cursor: pointer;
}

.press-page-link:hover {
  text-decoration: none;
  color: #065f86;
}

@media (max-width: 720px) {
  .search-section {
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 95px;
  }

  .press-search-field {
    width: 100%;
    margin: 0;
  }

  .press-search-field > .form-label {
    font-size: 4.2vw;
  }

  .search-page-content {
    padding-left: 45px;
    padding-right: 45px;
  }

  .press-search-button {
    margin: 0 10px;
  }

  .press-highlighted-item {
    margin: 0 20px;
  }

  .additional-items {
    grid-template-columns: 1fr;
    grid-gap: 50px;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
  }

  .press-additional-item {
    margin-bottom: 0;
  }

  .additional-item-content {
    display: block;
  }

  .highlighted-news {
    grid-template-columns: 1fr;
    margin-bottom: 0;
    grid-gap: 50px;
  }

  .press-header {
    padding-top: 15px;
  }

  .item-title {
    font-size: 4.85vw;
  }

  .press-highlighted-item-content,
  .continue-reading,
  .press-item-date {
    font-size: 3.6vw;
  }
}

@media (max-width: 520px) {
  
  .form-control.press-form-control {
    height: 44px;
    padding: 5px;
    font-size: 16px;
  }

  .search-section {
    margin: 0 0 50px;
  }
    
  .press-search-field > .form-label {
    font-size: 16px;
  }
}

@media (min-width: 1920px) {
  .press-search-field > .form-label {
    font-size: 1.05vw;
  }

  .item-title {
    font-size: 1.56vw;
  }

  .press-highlighted-item-content,
  .continue-reading,
  .press-item-date {
    font-size: 1.1vw;
  }

  .press-search-button {
    height: 2.6vw;
  }

  .form-control.press-form-control {
    height: 3.125vw;
    font-size: 1.1vw;
  }
}

@media (min-width: 2560px) {
  .item-title {
    font-size: 1.25vw;
  }
}

.spinner-border.text-primary {
  color: #1ca0db !important;
  margin: 45px auto 80px;
}

.no-news {
  margin-top: 30px;
}
