.and-many-more {
  margin-top: 30px;
  text-align: center;
}

h3.main-section-title {
    text-align: center;
    padding: 50px 0;
}

.partner-subtitle {
  text-align: center;
}

.partner-card.card {
  border-radius: 4px;
  box-shadow: -3px 5px 20px #00000029;
  margin-top: 50px;
  padding: 40px 75px;
}

.partner-card.card > div > .card-title {
  font-size: 2.7vw;
  font-weight: bold;
  margin-bottom: 10px; 
  word-wrap: nowrap;
}

.partner-card.card > div > .card-text {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 30px;
}

.partners-logo-container {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin: 0 -25px;
}

.image-wrapper {
  max-height: 150px;
  max-width: 150px;
  align-self: center;
}

.logo-list-item {
  margin: 10px 20px;
  height: 8vw;
  width: 8vw;
  object-fit: contain;
}

@media (max-width: 720px) {
  .logo-list-item {
    height: 20vw;
    width: 20vw;
    margin: 10px 5px;
  }

  .partner-card.card {
    text-align: center;
    padding: 40px 30px;
  }

  .partner-card.card > div > .card-title {
    font-size: 6.95vw;
  }

  .partner-card.card > div > .card-text {
    font-size: 3.9vw;
    line-height: 1.3em;
  }

  .and-many-more {
    font-size: 4.2vw;
    margin-top: 50px;
  }
}

@media (min-width: 1920px) {
  .partner-card.card > div > .card-title {
    font-size: 2.7vw;
  }

  .partner-card.card > div > .card-text {
    font-size: 1.15vw;
  }

  .logo-list-item {
    margin: 0.52vw 1.25vw;
    max-height: unset;
  }

  .and-many-more {
    font-size: 0.94vw;
    margin-top: 2.6vw;
  }
}
