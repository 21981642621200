@import '~bootstrap/dist/css/bootstrap.min.css';

.retailers-content-container {
  padding-bottom: 6vw;
}

.retailer-header {
  display: flex;
  align-items: flex-end;
  height: 450px;
  padding-bottom: 45px;
  background-repeat: no-repeat;
  background-size: 100%;
  position: relative;
}

.retailer-header-info {
  position: relative;
  width: 485px;
  padding: 105px 55px 65px;
  font-size: 22px;
  font-weight: bold;
  line-height: 28px;
  background-color: #ffffff;
}

.retailer-title-section {
  padding: 65px 0;
  text-align: center;
}

.retailer-title-section div {
  font-size: 32px;
  font-weight: bold;
}

.retailer-title-section p {
  font-size: 20px;
}

.brand-page-navigation-title {
  font-size: 48px;
  font-weight: bold;
  text-align: center;

  white-space: pre-wrap;
}

.brand-page-navigation-button {
  margin-right: 20px;
  margin-left: 20px;

  padding: 15px 25px;

  font-size: 24px;

  border: 1px solid #1ca0db;
  border-radius: 10px;

  text-decoration: none;
}

.brand-page-navigation-button:hover, .brand-page-navigation-button:focus {
  color: #1ca0db;

  background-color: #ffffff !important;

  border: 1px solid #1ca0db;

  text-decoration: none;

  outline: none;
}

.brand-page-content-title > div > span, .brand-page-content-title {
  font-size: 32px;
  font-weight: bold;
  text-align: center;
}

.brand-page-content-subtitle {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

.header-logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 163px;
  top: -83px;
  height: 166px;
  width: 166px;
  padding: 25px;
  border-radius: 83px;
  background-color: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
}

.header-logo {
  max-height: 100%;
  max-width: 100%;
  margin: 0 auto;
}

.retailer-details-section {
  padding-top: 30px;
  padding-bottom: 30px;
}

.retailers-section-title {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.1em;
  margin-bottom: 10px;
}

.retailers-text {
  font-size: 16px;
}

.available-brands-title {
  margin: 65px 0;
  font-size: 2.2vw;
  font-weight: bold;
  text-align: center;
}

.available-brand-logos {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.section-fullwidth-image {
  width: 100%;
  height: auto;
}

.retailer-image {
  height: auto;
  width: 37vw;
}

.retailer-section {
  flex-wrap: nowrap;
}

.retailer-section-part {
  width: 47%;
}

.header-image {
  width: 100%;
  height: 100%;
  position: absolute !important;
  top: 0;
  left: 0;
  z-index: 0;
}

.mobile-image {
  display: none !important;
}

.button-link {
  color: #fff;

  background-color: #1BA0DB;

  padding: 15px 25px;

  border: 1px solid #1BA0DB;
  border-radius: 10px;

  font-size: 1.25rem;
  font-weight: bold;
  text-decoration: none;
}

.button-link:hover, .button-link:focus {
  color: #1BA0DB;

  background-color: #fff;

  text-decoration: none;
}

.delivery-box {
  position: relative;
  padding-bottom: 30px;
}

.delivery-link {
  position: absolute;

  top: 5%;
  right: 5%;

  color: #1BA0DB;

  border: 1px solid #1BA0DB;
  border-radius: 10px;
  background-color: #fff;

  padding: 1rem 2rem;
}

.delivery-link:hover {
  color: #1BA0DB;
}


@media (max-width: 720px) {
  .available-brands-title {
    font-size: 5.8vw;
  }

  .retailer-title-section div {
    font-size: 5.8vw;
  }
  
  .retailer-title-section p {
    font-size: 3.2vw;
  }

  .brand-page-content-title > div > span, .brand-page-content-title {
    font-size: 5.8vw;
  }

  .brand-page-content-subtitle {
    font-size: 3.4vw;
  }

  .brand-page-navigation-title {
    font-size: 6.5vw;
  }

  .retailers-content-container {
    padding-bottom: 15vw;
  }

  .retailers-section-title {
    font-size: 5.3vw;
  }

  .retailers-text {
    font-size: 3.2vw;
  }

  .retailer-section {
    margin: 0; 
  }

  .retailer-section-part {
    width: 100%;
  }

  .pagecontent-text-block {
    text-align: center;
  }

  .button-link {
    font-size: 1rem;

    text-align: center;
  }
}

@media (max-width: 520px) {
  .retailer-header-info {
    width: 90%;
    font-size: 24px;
    padding: 90px 30px 40px;
    text-align: center;
    margin: 0 auto;
  }

  .header-logo-container {
    left: calc(50% - 78px);
  }

  .desktop-image {
    display: none !important;
  }

  .mobile-image {
    display: block !important;
  }
}

@media (min-width: 721px) {
  .retailer-section-part.second-section-part {
    width: 47%;
  }

  .reversed-section > .retailer-section-part {
    width: 47%;
  }

  .reversed-section > .retailer-section-part.second-section-part {
    width: 44%;
  }
}

@media (min-width: 1920px) {
  .available-brands-title {
    font-size: 2.2vw;
  }

  .retailer-title-section div {
    font-size: 2.2vw;
  }
  
  .retailer-title-section p {
    font-size: 1.2vw;
  }

  .brand-page-navigation-title {
    font-size: 3vw;
  }

  .retailers-section-title {
    font-size: 1.9vw;
  }

  .retailers-text {
    font-size: 1.2vw;
  }

  .retailer-header {
    height: 530px;
    padding-bottom: 75px;
  }
}

