.dsar-page {
    max-width: 100%;
    height: 1500px;
    margin: auto;
}

.dsar-page iframe {
    width: 100%;
    height: 100%;
    margin-top: 20px;
    border: none;
}
