.st0 {
  fill: url(#Path_7753_00000087394876796316378360000004020904354062692768_);
}
.st1 {
  fill: #ffffff;
}
.st2 {
  clip-path: url(#SVGID_00000016056295091089419850000008057715229634180260_);
}
.st3 {
  clip-path: url(#SVGID_00000046306089219383717710000012808402955853185696_);
}
.st4 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #0054a4;
}
.st5 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #f02e25;
}
.st6 {
  fill: url(#Path_7753-2_00000077320498878041613340000017572437527740189113_);
}
.st7 {
  fill: #dc1b32;
}
.st8 {
  fill: url(#Path_7753-2_00000010276103615973925000000005172030494092935312_);
}
.st9 {
  fill: #c8102f;
}
.st10 {
  fill: url(#Path_7753-3_00000018233231131108972820000014516829134055309220_);
}
.st11 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #97326c;
}
.st12 {
  fill: url(#Path_7753-4_00000174598961296712747250000000054639641940901043_);
}
.st13 {
  fill: #13428b;
}
.st14 {
  fill: url(#Path_7753-6_00000128467771108971454520000014605551496772517558_);
}
.st15 {
  fill: #de0e1a;
}
.st16 {
  fill: #014591;
}
.st17 {
  fill: url(#Path_7753-7_00000121277639683929728920000009831153583645580717_);
}
.st18 {
  clip-path: url(#SVGID_00000085939574002290257320000007668508372872133016_);
}
.st19 {
  fill: #2d2e87;
}
.st20 {
  clip-path: url(#SVGID_00000087401433621604541650000000679477265166300577_);
}
.st21 {
  clip-path: url(#SVGID_00000145781160673690447730000000827006577285889415_);
}
.st22 {
  clip-path: url(#SVGID_00000023987225662575605840000009078563738410040961_);
}
.st23 {
  clip-path: url(#SVGID_00000015340970895646913690000017452259698615923875_);
}
.st24 {
  clip-path: url(#SVGID_00000002373897859292245130000009352724964246237367_);
}
.st25 {
  clip-path: url(#SVGID_00000152238824255174320600000012378602331535947168_);
}
.st26 {
  fill: #9bc31c;
}
.st27 {
  clip-path: url(#SVGID_00000152228788766399990350000014529909548503740039_);
}
.st28 {
  fill: url(#Path_7753-8_00000164499066927801626140000004405531702530806953_);
}
.st29 {
  clip-path: url(#SVGID_00000098916440199436961430000005945030265302208901_);
}
.st30 {
  clip-path: url(#SVGID_00000118361168030399375700000016750249663966542261_);
  fill: #52add1;
}
.st31 {
  fill: url(#Path_7753-10_00000086666302850322395090000010771477312558582669_);
}
.st32 {
  fill: #5a5758;
}
.st33 {
  fill: url(#Path_7753-12_00000039831554619534489730000014609398631691891860_);
}
.st34 {
  fill: #f5ebdc;
}
.st35 {
  fill: #ff8732;
}
.st36 {
  fill: #d62300;
}
.st37 {
  fill: url(#Path_7753-13_00000099641446765230558910000017743743445439030440_);
}
.st38 {
  clip-path: url(#SVGID_00000054953140238588561780000001615023235323380113_);
}
.st39 {
  fill: #ffbc0d;
}
.st40 {
  fill: url(#Path_7753-5_00000070806740165859181090000000552640844599152023_);
}
.st41 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #004b8d;
}
.st42 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #fff200;
}
.st43 {
  fill: url(#Path_7753_00000090985732090813016600000004994534458021235612_);
}
.st44 {
  clip-path: url(#SVGID_00000139260471744219051260000008890687614119527598_);
}
.st45 {
  clip-path: url(#SVGID_00000101796658888292373140000005644764175604275109_);
}
.st46 {
  fill: url(#Path_7753-2_00000109720332921742319360000014171615120962061752_);
}
.st47 {
  fill: url(#Path_7753-2_00000092428383917748837940000009925328305201629346_);
}
.st48 {
  fill: url(#Path_7753-3_00000058566723868725583970000015489686250651508138_);
}
.st49 {
  fill: url(#Path_7753-4_00000037674715069705788010000014114353876219943344_);
}
.st50 {
  fill: url(#Path_7753-6_00000016768847711053001520000001101209828416970115_);
}
.st51 {
  fill: url(#Path_7753-7_00000063608268092954109620000012394321201428122794_);
}
.st52 {
  clip-path: url(#SVGID_00000137824984059604687090000015239202845853009854_);
}
.st53 {
  clip-path: url(#SVGID_00000039120266145714606100000014352550022490089656_);
}
.st54 {
  clip-path: url(#SVGID_00000101793177353375621970000013494543743020956341_);
}
.st55 {
  clip-path: url(#SVGID_00000081621512266377814250000018151676136901932940_);
}
.st56 {
  clip-path: url(#SVGID_00000097483560443239749330000009537132547135458189_);
}
.st57 {
  clip-path: url(#SVGID_00000042735145338547607480000014397429131195794852_);
}
.st58 {
  clip-path: url(#SVGID_00000044877455085056253540000010180551947638101898_);
}
.st59 {
  clip-path: url(#SVGID_00000081633959049048511630000010959677363657008768_);
}
.st60 {
  fill: url(#Path_7753-8_00000177476964787035018620000007397693054036076707_);
}
.st61 {
  clip-path: url(#SVGID_00000172433323527078577030000016518862926266360963_);
}
.st62 {
  clip-path: url(#SVGID_00000075140492836649649860000010060944523127290757_);
  fill: #52add1;
}
.st63 {
  fill: url(#Path_7753-10_00000149347896201196527940000018186777023098212029_);
}
.st64 {
  fill: url(#Path_7753-12_00000151517584545797520360000014062928120185958568_);
}
.st65 {
  fill: url(#Path_7753-13_00000152265136503611630240000003259968247037849015_);
}
.st66 {
  clip-path: url(#SVGID_00000061432723216798798580000000549714543925105286_);
}
.st67 {
  fill: url(#Path_7753-5_00000053524144627082638040000016608773257764120216_);
}
