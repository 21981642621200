.footer-outer-container {
  width: 100%;
  margin: 0 auto;
  color: #000000;
  background-color: #ffffff;
  -webkit-box-shadow: 0px -3px 6px #00000029;
  -moz-box-shadow: 0px -3px 6px #00000029;
  box-shadow: 0px -3px 6px #00000029;
  z-index: 1;
}

.ot-sdk-show-settings:hover {
  background-color: transparent !important;

  color: #1ca0db !important;
  text-decoration: none !important;
}

.ot-sdk-show-settings {
  background-color: transparent;
  border: 0px !important;
  color: inherit !important;
  text-decoration: none !important;
  line-height: 1.75em !important;

  font-size: 16px !important;
  font-weight: 500 !important;

  padding: 0 !important;
}

.footer-inner-container {
  display: flex;
  margin: 0 auto;
  padding-top: 40px;
  padding-bottom: 40px;
}

.links {
  font-size: 16px;
  font-weight: 500;
  flex: 1;
}

.links > a {
  color: inherit;
  text-decoration: none;
  line-height: 1.75em;
}

.links > a:hover {
  color: #1ca0db;
  text-decoration: none;
}

.col-12 {
  padding: 0 !important;
}

.column {
  display: flex;
  flex-direction: column;
}

.footer-section {
  padding: 15px 0;
  margin: 0 15px;
  min-width: 190px;
  align-items: flex-start;
}

.social-links {
  min-width: 275px;
}

.footer-section-title {
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 25px;
}

.social-icon {
  width: 43px !important;
  height: 43px !important;
  margin: 0 5px 10px !important;
}

.social-networks {
  display: flex;
  justify-content: space-between;
  margin: 0 -5px;
  width: 100%;
}

.footer-bottom {
  font-size: 16px;
  text-align: center;
  margin-bottom: 15px;
}

@media (min-width: 1920px) {
  .footer-section-title {
    font-size: 1.05vw;
  }

  .links {
    font-size: 0.85vw;
  }
}

@media (max-width: 720px) {
  .links {
    font-size: 3.9vw;
  }

  .social-networks {
    display: flex;
    padding: 0 8.6vw;
  }

  .social-links {
    width: 100%;
    margin: 0;
  }

  .social-icon {
    width: 12vw !important;
    height: 12vw !important;
  }

  .footer-inner-container {
    padding-top: 9.3vw;
    padding-bottom: 9.3vw;
    flex-direction: column-reverse;
    align-items: center;
    text-align: center;
    margin: 0 auto;
  }

  .footer-section-title {
    display: none;
  }

  .footer-section {
    align-items: center;
  }
}

@media (max-width: 520px) {
  .footer-inner-container {
    padding-top: 45px;
    padding-bottom: 45px;
  }
}

